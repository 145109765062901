.boundaries-map {
    width: 600px;
    height: 400px;
    box-shadow: 0px 0px 12px var(--theme-light-1);
    overflow: hidden;
}

.boundaries-map-leaflet {
    position: relative;
    height: 100%;
}

.key-notes .key-notes__editor.key-notes__editor--preview{
  border: none;
  margin: -10px;
}

.key-notes .key-notes__editor.key-notes__editor--preview .mde-header {
  display: none;
}

.key-notes .key-notes__editor .mde-tabs{
  display: none;
}

.key-notes .key-notes__actions svg path{
  fill: var(--theme-dark-2);
}

.key-notes .key-notes__actions button {
  cursor: pointer;
}

.key-notes .key-notes__actions button:hover {
  opacity: 0.6;
}

.key-notes .key-notes__actions {
  margin-left: 0.5rem;
}

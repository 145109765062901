.manager-content {
  padding: 1rem 1.5rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.manager-content__table-wrapper {
  height: 320px;
  max-height: 320px;
  min-height: 320px;
  overflow-y: auto;
  overflow-x: auto;
}

.manager-content__table {
  width: 100%;
  background-color: white;
  color: #363636;
  overflow-y: auto;
}

.manager-content__table td {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: middle;
  cursor: default;
}

.manager-content__table .highlighted {
  background-color: rgba(211, 225, 238, 0.4);
}

.manager-content__table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em 0.5em 0.75em;
  vertical-align: middle;
}

.manager-content h5 {
  margin: 1rem 1rem 1rem 0rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  height: 32px;
  display: flex;
  align-items: center;
}

.manager-content__container {
  display: flex;
  margin-bottom: 1rem;
}

.manager-content__container .key-notes {
  width: 100%;
}

.manager-content .content-tabs {
  margin-top: 0;
}

.manager-content__table tr.active {
  background-color: #d3e1ee;
}

.manager-content__table .manager-content__address {
  margin-right: 40px;
  display: inline-block;
}

.manager-content__table .manager-content__input-edited {
  color: blue;
  font-weight: 900;
}

.manager-content__table .manager-content__input:disabled {
  cursor: not-allowed;
}

.manager-content__table .pointer {
  cursor: pointer;
}

.manager-content .content-tabs__tabs {
  margin-bottom: 0;
}

.manager-content__no-rating {
  width: 100%;
  background-color: #d3e1ee;
  margin: 0 0 1rem 0;
  padding: 0.5em 1rem;
}

.manager-content__input {
  width: 100%;
}

.manager-content__table td.manager-content__table--nop {
  padding: 0 0.75em;
}

.manager-content__table .manager-content__table__additional_header th {
  border: none;
}

.manager-content__table .manager-content__table__additional_header th.subtitle {
  border-bottom: 1px solid #dbdbdb;
  text-align: center;
}

.manager-content__container__actions {
  display: flex;
}

.content_search_wrapper {
  padding: 0 0.5rem;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.content_search_wrapper .content-search__select {
  z-index: 10;
  width: 50%;
}

.switch-container ul {
  list-style-type: none;
  display: flex;
  justify-content: stretch;
  border-radius: 4px;
  background-color: var(--theme-light-2);
}

.switch-switchoption {
  cursor: pointer;
  width: 100%;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--theme-dark-1);
  border-right: 1px solid #fff;
}

.switch-switchoption:first-child {
  border-radius: 4px 0px 0px 4px;
}

.switch-switchoption:last-child {
  border-radius: 0px 4px 4px 0px;
  border-right: none;
}

.switch-switchoption-selected {
  color: white;
  background-color: var(--theme-dark-2);
}

.switch-switchoption:hover {
  background-color: var(--theme-dark-1);
  color: var(--theme-light-2);
}

.switch-container-disabled .switch-switchoption,
.switch-container-disabled .switch-switchoption:hover {
  cursor: no-drop;
  color: white;
  background-color: var(--theme-light-2);
}

.switch-container-disabled .switch-switchoption-selected,
.switch-container-disabled .switch-switchoption-selected:hover {
  background-color: var(--theme-dark-1);
  color: var(--theme-light-2);
}

.switchoption-image {
  width: 52px;
  height: 52px;
}

.switchoption-image img {
  width: 48px;
  height: 48px;
  margin: 2px;
  border-radius: 2px;
}

.switchoption-text {
  font-weight: 500;
  padding: 8px 6px;
}

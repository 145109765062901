.modal {
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  background-color: white;
  box-shadow: 0px 0px 12px var(--theme-light-1);
  z-index: 1001;
  border: 2px solid white;
  width: 100%;
  min-width: 320px;
  padding: 1rem;
}

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.modal__close {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__close::after {
  display: inline-block;
  content: "\00d7";
  font-size: 1.5rem;
}

.profiler__content {
  padding: 1rem 10rem;
}

.profiler__content > h1 {
  color: #667883;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  margin: 1rem 1rem 2rem 0rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-weight: 600;
}

.profiler__content__data {
  width: 100%;
  max-width: 920px;
}

.profiler__content__data img{
  max-height: 290px;
  width: auto;
}

.profiler__content__data h5 {
  margin: 1rem 1rem 1rem 0rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  height: 32px;
  display: flex;
  align-items: center;
}

.profiler__content__data p {
  margin-bottom: 1rem;
  line-height: 1.5;
  letter-spacing: 0;
}

.profiler__nodata {
  display: flex;
  min-height: calc(100vh - 78px);
  justify-content: center;
  align-content: center;
  align-items: center;
}

.profiler__nodata > div.highlighted {
  width: 100%;
  background-color: #d3e1ee;
  padding: 3rem 10rem;
}

.profiler__nodata > div, .profiler__nodata p {
  text-align: center;
  font-weight: 500;
  font-family: 'Roboto', "Work Sans", sans-serif;
}

.profiler__nodata img {
  width: 32px;
  height: auto;
}

.configuration-save-dialog {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.configuration-save-dialog > *:first-child {
    margin-right: 12px;
}

.selected-attributes-list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
}

.selected-attributes-list li {
    margin-right: 8px;
    margin-bottom: 2px;
    font-size: 10pt;
    border: 1px var(--theme-light-2) solid;
    color: var(--theme-dark-2);
    border-radius: 8px;
    padding: 0px 8px;
}

.configuration-section-title {
    background-color: var(--theme-light-2);
    padding: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 600;
}
.composition-draft-wrapper > p {
    margin-bottom: 1rem;
}

.composition-draft-wrapper > .input-container {
    margin-bottom: 1rem;
}

.composition-draft-wrapper__buttons {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
}

.button-composition {
    font-size: 0.7rem;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border-radius: 25px;
}

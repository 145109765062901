.table {
    min-width: 650px;
}

.container {
    height: 440px;
}

.chip{
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
}
.chip:hover {
    cursor: pointer;
    background-color: #d3e1ee !important;
    border: 1px solid #d3e1ee !important;
}


.chip.selected {
    background-color: #d3e1ee !important;
    border: 1px solid #d3e1ee !important;
}

.selected span {
    font-weight: 500;
}

.heading1 {
  font-size: 24pt;
  margin-bottom: 24px;
}

.heading4 {
  font-weight: 500;
  margin-bottom: 24px;
}

.paragraph {
  font-weight: 500;
  float: left;
  margin-right: 12px;
}

.login-container {
  height: 100vh;
  padding: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container > div {
  width: 480px;
}

.button-right {
  text-align: right;
}

@media (max-width: 420px) {
  .login-container > div {
    width: 320px;
  }
}

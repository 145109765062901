.auto-growing-input, .auto-growing-field {
  cursor: text;
  display: block;
  white-space: nowrap;
  position: relative;
}

.auto-growing-field {
  cursor: pointer;
}

.auto-growing-field.auto-growing-input--edited {
  font-weight: bolder;
  color: var(--theme-dark-2);
  text-decoration: underline;
}

.auto-growing-input.auto-growing-input--edited {
  font-weight: bolder;
  color: var(--theme-dark-2);
  text-decoration: underline;
}
.auto-growing-input__tooltip.tooltip {
  display: block;
}
.auto-growing-input__tooltip.tooltip::after {
  padding: 0.5rem 1rem;
  min-height: unset;
  min-width: unset;
  max-width: unset;
  cursor: default;
  bottom: 1.3rem;
  white-space: nowrap;
}

.auto-growing-input.auto-growing-input--read-only {
  cursor: pointer;
}

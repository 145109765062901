.content-tabs {
  margin-bottom: 3rem;
  position: relative;
}

.content-tabs__tabs {
  /*margin: 1rem 0 1rem 0rem;*/
  border-bottom: 1px solid #ddd;
}

.content-tabs__tabs span {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: -1px;
  padding: 0.5em;
  cursor: pointer;
  color: var(--theme-light-1);
  display: inline-block;
}

.content-tabs__tabs span:hover {
  color: var(--theme-normal);
}

.content-tabs__tabs span.active {
  border-bottom: 1px solid var(--theme-dark-2);
  color: var(--theme-dark-2);
}

.content-tabs__tabs span.disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.content-tabs.content-tabs--inline {
  display: inline-block;
}

.content-tabs.content-tabs--inline .content-tabs__tabs {
  margin: 1rem 0;
}

.manager .content-tabs .activity-indicator-container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.radiogroup-container ul {
  list-style-type: none;
}

.radio-item-container {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  cursor: pointer;
}

.radio-item-circle {
  width: 24px;
  height: 24px;
  background-color: var(--theme-light-2);
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-item-circle-inner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
}

.radio-item-container:hover .radio-item-circle-inner {
  background-color: var(--theme-normal);
}

.radio-item-container-selected .radio-item-circle-inner,
.radio-item-container-selected:hover .radio-item-circle-inner {
  background-color: var(--theme-dark-2);
}

.radio-item-text .tooltip svg {
  width: 16px;
}
.radio-item-text .tooltip {
  float: right;
}
.radio-item-text .tooltip::after {
  width: 160px;
  min-height: unset;
}

.radio-item-text {
  display: inline-block;
  width: calc(100% - 32px);
}

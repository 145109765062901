.slider-container {
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 24px;
  height: 48px;
  position: relative;
}

.slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  margin-top: 10px;
  border-radius: 2px;
}

.handle {
  position: absolute;
  z-index: 1;
  width: 16px;
  height: 24px;
  margin-left: -8px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.handle-text {
  margin-top: -20px;
  font-weight: 500;
  font-size: 10pt;
  background-color: white;
  padding: 0px 8px;
  border-radius: 4px;
  border: 1px var(--theme-light-2) solid;
  transition: 0.2s;
  color: var(--theme-normal);
}

.track {
  position: absolute;
  height: 4px;
  margin-top: 10px;
  border-radius: 2px;
}

.slider-rail {
  background-color: var(--theme-light-2);
}

.handle,
.track {
  background-color: var(--theme-normal);
}

.slider-root:hover .handle {
  background-color: var(--theme-dark-1);
}

.slider-root .handle:hover {
  background-color: var(--theme-dark-2);
  z-index: 2;
}

.slider-root:hover .handle-text {
  color: var(--theme-dark-1);
}

.slider-root .handle:hover .handle-text {
  color: var(--theme-dark-2);
  background-color: var(--theme-light-1);
  border: 1px var(--theme-light-1) solid;
}

.async-loading-indicator {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  
}

.async-loading-indicator img {
  width: 38px;
  height: 38px;
}
.table {
    border-spacing: 0;
    width: 100%;
}

.table thead th > div {
    font-weight: 600;
    display: flex;
    align-items: center;
}

.table thead th > div .table__sort_icon {
    margin-left: 0.5rem;
    width: 1rem;
    height: 1rem;
}

.table th,
.table td {
    margin: 0;
    padding: 0.5rem;
    background-color: white;
    box-sizing: border-box;
}

.table thead tr:last-child th {
    border-bottom: 1px solid #dbdbdb;
}

.table th {
    white-space: nowrap;
}

.table td {
    border-bottom: 1px solid #dbdbdb;
    background-color: white;
}

.pagination {
    padding: 0.5rem;
}

.table .tr.active td {
    background-color: #d3e1ee;
}

.table .tr.active .content-actions button svg path {
    fill: #4f5d66;
}

.table .tr.active .content-actions button.redo-icon,
.table .tr.active .content-actions button.bin-icon {
    background-color: #4f5d66;
    border-radius: 50%;
}

.table .tr.active .content-actions button.redo-icon svg path,
.table .tr.active .content-actions button.bin-icon svg path {
    fill: #d3e1ee;
}

.table .tr.clickable {
    cursor: pointer;
}

.table__scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    /*min-height: 200px;*/
}

.table__maxHeight {
    height: 40vh;
}

.table__scroll::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table__scroll::-webkit-scrollbar:vertical {
    width: 11px;
}

.table__scroll::-webkit-scrollbar:horizontal {
    height: 11px;
}

.table__scroll::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
}

.table__scroll::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
}

.table thead th.grupeTitle > div {
    justify-content: center;
}

.table__scroll .table thead th {
    background: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
}

.table__scroll .table.isScrollY thead th {
    background-color: #b9c6d1;
}

/* .table__scroll td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
} */

.table th.highlighted {
    background-color: #edf3f8 !important;
}

.table td.highlighted {
    background-color: #edf3f8;
}

.table__scroll .table thead th.sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 5;
}

.table tr th.grupeTitle {
    border-bottom: 1px solid #dbdbdb;
}

.table__scroll .table.isScrollX tr th.sticky {
    background-color: #b9c6d1;
}

/* .table__scroll th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
} */

.table__scroll td.sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
}

.table__scroll .table.isScrollX td.sticky {
    background-color: #b9c6d1;
}

.table .label-name {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
}

.table .label-name > div {
    margin-left: 0.5rem;
}

.table--location .table__scroll .table thead tr:last-child th {
    top: 35px;
}

.table .select__value-container {
    padding: 0;
    min-height: unset;
    max-height: 1rem;
}

.table .select__control {
    min-height: unset;
    max-height: 1rem;
}

.table .select__indicator.select__dropdown-indicator {
    padding: 0 8px;
    max-height: 1rem;
}

.tab {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
  color: var(--theme-light-1);
}
.tab.tab--disable {
  color: var(--theme-dark-2);
  opacity: 0.3;
}

.tab:hover {
  color: var(--theme-dark-2);
}

.tab:hover .tab-icon g,
.tab:hover .tab-icon path {
  fill: var(--theme-dark-2);
}

.tabs li[aria-selected="true"] .tab {
  color: var(--theme-dark-2);
  border-left: 12px var(--theme-dark-2) solid;
  padding-right: 12px;
}

.tab-icon {
  width: 36px;
  height: 36px;
  margin-bottom: 4px;
  text-align: center;
}

.tab-icon svg {
  width: 100%;
  height: 100%;
}

.tab-icon g,
.tab-icon path {
  fill: var(--theme-light-1);
}

.tabs li[aria-selected="true"] .tab .tab-icon g,
.tabs li[aria-selected="true"] .tab .tab-icon path {
  fill: var(--theme-dark-2);
}

.tab-text {
  font-size: 11pt;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

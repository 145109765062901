.input-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label {
  padding-right: 24px;
  cursor: text;
}

.input {
  width: 100%;
  padding: 12px 18px;
  border: 2px var(--theme-light-2) solid;
  border-radius: 4px;
  background-color: white;
  font-family: 'Roboto', "Work Sans", sans-serif;
  font-weight: 500;
  transition: 0.2s;
  box-sizing: border-box;
}

.input::placeholder {
  color: var(--theme-light-1);
}

.input:hover,
.input:focus {
  border-color: var(--theme-dark-1);
}

.input.button-inside {
  padding-right: 48px;
}

.input-button-inside {
  position: absolute;
  right: 0px;
  width: 28px;
  height: 28px;
  margin: 0px 12px;
  cursor: pointer;
}

.input-button-inside g {
  fill: var(--theme-light-2);
  transition: 0.2s;
}

.input-button-inside:hover g,
.input:hover + .input-button-inside g,
.input:focus + .input-button-inside g {
  fill: var(--theme-dark-1);
}

.input.input--small {
  padding: 6px 9px;
  font-size: 0.8rem;
}

@media (max-width: 420px) {
  .input-container {
    flex-wrap: wrap;
  }

  .label {
    margin-bottom: 12px;
  }
}

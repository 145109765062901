.tabs {
  z-index: 402;
  position: fixed;
  top: var(--header-height);
  height: 100%;
  display: grid;
  grid-template-columns: 120px auto;
  grid-template-rows: auto;
  padding-top: 36px;
  background-color: white;
  box-shadow: 0px 0px 12px var(--theme-light-1);
}

.tabs.tabs--iframe {
  top: 0;
}

.tablist {
  grid-column-start: 1;
  display: flex;
  flex-direction: column;
}

.tablist .spacer {
  flex-grow: 0.8;
}

.panel {
  position: relative;
  grid-column-start: 2;
  overflow: auto;
}

.advanced-mode-request {
  width: 360px;
  padding-right: 36px;
  margin-bottom: calc(var(--header-height) + 36px + 36px);
}

.advanced-mode-request a{
  margin-top: 1rem;
  color: var(--theme-dark-2);
  text-decoration: none;
  display: inline-block;
}
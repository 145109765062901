.user-panel-about {
    display: flex;
    flex-flow: column;
}

.user-panel-about-components {
    margin-left: 24px;
    list-style-type: symbols(cyclic "—");
}

.user-panel-about-components li{
    margin-top: 12px;
}

.panel-item-container {
  margin-bottom: 24px;
  border-left: 4px var(--theme-light-2) solid;
  padding-left: 8px;
  transition: 0.2s;
  overflow: visible;
}

.panel-item-container:hover {
  border-left: 4px var(--theme-normal) solid;
}

.panel-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.panel-item-header .heading4 {
  margin: 0px;
}

.panel-item-buttons {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.panel-item-buttons > div {
  margin-left: 12px;
}

.panel-item-content ul{
  list-style-type: none;
}
.quota-bar {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 36px;
  background-color: var(--theme-red-2);
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
}

.quota-bar .quota-bar__cta {
  transition: 0.2s;
  margin-left: 12px;
  padding: 8px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 12px var(--theme-light-1);
  color: var(--theme-red-2);
  border-radius: 4px;
  text-decoration: none;
}

.quota-bar .quota-bar__cta:hover {
  color: rgba(255, 255, 255, 1);
  background-color: var(--theme-normal);
}

.quota-bar .quota-bar__cta--close {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.quota-bar .quota-bar__cta--close:before,
.quota-bar .quota-bar__cta--close:after {
  position: absolute;
  left: 8px;
  content: " ";
  height: 12px;
  width: 2px;
  background-color: white;
}
.quota-bar .quota-bar__cta--close:before {
  transform: rotate(45deg);
}
.quota-bar .quota-bar__cta--close:after {
  transform: rotate(-45deg);
}

.searchselect-container {
  position: relative;
}

.searchselect-option-container {
  position: absolute;
  background-color: white;
  border: 2px var(--theme-light-2) solid;
  border-radius: 0px 0px 4px 4px;
  margin-top: -2px;
  width: 100%;
  z-index: 1;
}

.searchselect-option-container ul {
  list-style-type: none;
}

.searchselect-option-container-closed {
  display: none;
}

.searchselect-option-item {
  padding: 12px;
  border-bottom: 1px var(--theme-light-2) solid;
  cursor: pointer;
}

.searchselect-option-item:hover {
  background-color: var(--theme-light-2);
}

.searchselect-option-item.searchselect-option-item-disabled {
  cursor: auto;
}

.searchselect-option-item:hover.searchselect-option-item-disabled {
  background-color: white;
}

.searchselect-option-item:last-child {
  border: none;
}

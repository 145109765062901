/* VARIABLES */
:root {
    --header-height: 78px;
    --theme-light-2: #d3e1ee;
    --theme-light-1: #b9c6d1;
    --theme-light-3: #E5E5E5;
    --theme-normal: #879fae;
    --theme-dark-1: #667883;
    --theme-dark-2: #4f5d66;
    --theme-dark-3: #697782;
    --theme-red-1: #f03b20;
    --theme-red-2: #bd0026;
    --theme-yellow-1: #d5d791;
    --theme-yellow-2: #efef6e;
}

/* FONTS */
@font-face {
    font-family: 'Roboto', "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    src: local("Work Sans Light"), local("WorkSans-Light"),
    url("./fonts/WorkSans-Light.ttf");
}

@font-face {
    font-family: 'Roboto', "Work Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    src: local("Work Sans Medium"), local("WorkSans-Medium"),
    url("./fonts/WorkSans-Medium.ttf");
}

/* RESET */
html,
body,
div,
form,
fieldset,
legend,
caption,
label,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
input,
button {
    font-size: 100%;
    font-weight: normal;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
    border: 0;
    outline: 0;
    background: transparent;
    box-sizing: border-box;
    font-family: 'Roboto', "Work Sans", sans-serif;
    font-weight: 300;
    letter-spacing: -0.3pt;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
}

th,
td {
    text-align: left;
    vertical-align: top;
}

img,
button {
    border: none;
}

* {
    outline: 0 !important;
}

/* ALERT */
#alert-stack {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
}

.marker-cluster-small {
    background-color: #3274A3 !important;
}

.marker-cluster-small div {
    background-color: #2A81CB !important;
    color: #fff !important;
}

.marker-cluster-medium {
    background-color: #3274A3 !important;
}

.marker-cluster-medium div {
    background-color: #2A81CB !important;
    color: #fff !important;
}

.profileAvatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #BDBDBD;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    color: white;
    margin-top: 16px;
    margin-right: 16px;
}

.MuiChip-colorPrimary {
    color: rgba(0, 0, 0, 0.87) !important;
}

.MuiChip-outlinedPrimary {
    color: rgba(0, 0, 0, 0.87) !important;
}

.mde-header .mde-tabs button {
    padding-left: 4px;
    padding-right: 4px;
}

.mde-header .mde-tabs button.selected {
    background-color: var(--theme-light-2);
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 500;
}

.mde-header .mde-tabs button:hover {
    background-color: var(--theme-dark-1);
    color: white;
}

.mde-header .mde-tabs button.selected:hover {
    background-color: var(--theme-light-2);
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 500;
}

.map-building-container {
    position: relative;
    width: 100%;
    height: 400px;
}

.map-building-leaflet {
    position: relative;
    height: 100%;
    z-index: 50;
}

.new-building-container .search-container {
    z-index: 60;
    position: relative;
    margin-left: 0;
    margin-bottom: 16px;
}

.asset-viewer-component .content-files__wrapper {
    max-height: 450px !important;
}

.asset-viewer-image-content {
    cursor: pointer;
}

.activity-indicator-container {
  position: absolute;
  top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 401;
  user-select: none;
}

.activity-indicator-inner {
  background: white;
  box-shadow: 0px 0px 12px var(--theme-light-1);
  padding: 12px 24px;
  border-radius: 0px 0px 4px 4px;
  display: flex;
  align-items: center;
  height: 56px;
}

.activity-indicator-icon {
  width: 32px;
  height: 32px;
  margin-right: 24px;
}

.activity-indicator-icon g {
  fill: var(--theme-red-2);
}

.activity-message-error {
  color: var(--theme-red-2);
}

.profiler-table {
  width: 100%;
  background-color: white;
  color: #363636;
}

.profiler-table td, .profiler-table th {
  border: 1px solid #dbdbdb;
  border-width: 0 0 1px;
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.profiler-table .shaded {
  background-color: #b9c6d1;
}
.profiler {
  margin-left: 120px;
}

.profiler .react-mde {
  width: 100%;
  max-width: 720px;
}

.profiler__header {
  background-color: #d3e1ee;
  padding: 3rem 10rem;
}

.profiler__header h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4a4a4a;
}

.profiler__header h2 small {
  font-size: 1rem;
  display: block;
  font-weight: normal;
}

.alert-container {
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: rgba(79, 93, 102, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  box-shadow: 0px 0px 12px var(--theme-light-1);
  border-radius: 12px;
  padding: 24px;
  background-color: white;
  min-height: 155px;
  min-width: 310px;
  max-width: 465px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.alert-buttons {
  display: flex;
  justify-content: flex-end;
}

.alert-buttons > div {
  margin-left: 12px;
}

.button {
  background-color: var(--theme-light-2);
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-family: 'Roboto', "Work Sans", sans-serif;
  font-weight: 500;
  cursor: pointer;
  color: var(--theme-dark-1);
  fill: var(--theme-dark-1);
  transition: 0.2s;
}

.button-icon {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.button-icon svg {
  height: 20px;
  max-width: 20px;
}

.button-icon g {
  fill: var(--theme-dark-1);
}

.button-switch-on,
.button-switch-on g,
.button-submit,
.button-submit g {
  background-color: var(--theme-dark-2);
  color: var(--theme-light-2);
  fill: var(--theme-light-2);
}

.button-isSecondary{
  background-color: white;
  border: 1px var(--theme-light-3) solid;
  color: var(--theme-dark-3);
  fill: var(--theme-dark-3);
}

.button-isSecondary:hover{
  border: 1px var(--theme-dark-1) solid;;
}

.button:hover,
.button-icon:hover g {
  background-color: var(--theme-dark-1);
  color: var(--theme-light-2);
  fill: var(--theme-light-2);
}

.button-switch-on:hover,
.button-submit:hover {
  color: white;
  fill: white;
}

.button-disabled,
.button-disabled:hover {
  background-color: white;
  border: 2px var(--theme-light-2) solid;
  color: var(--theme-light-2);
  fill: var(--theme-light-2);
  cursor: no-drop;
}

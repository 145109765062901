.metadata-visualization {
  margin: 12px 0px;
}

.metadata-visualization p {
  margin-bottom: 12px;
}

.metadata-visualization ul {
  list-style-type: none;
}

.metadata-visualization li {
  transition: 0.2s;
  border-bottom: 1px white solid;
  display: flex;
}

.metadata-visualization li:hover {
  border-bottom: 1px var(--theme-light-2) solid;
}

.metadata-name {
  flex-grow: 2;
}

.metadata-value {
  font-weight: 500;
  display: block;
  text-align: right;
}

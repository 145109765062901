.search-container {
  margin-left: 36px;
  display: flex;
  align-items: center;
}

.search-input {
  width: 360px;
}

.search-result-options-list {
  height: var(--header-height);
  overflow: scroll;
}

.editor-entry-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.editor-entry-input {
  width: 224px;
}

.editor-entry-input[type="checkbox"] {
  margin-top: 10px;
}

.editor-entry-transform {
  margin-bottom: 12px;
}

.editor-entry-transform:last-child,
.editor-entry-mode-simple .editor-entry-transform:last-child {
  margin-bottom: 0px;
}

.editor-entry-mode-simple .editor-entry-transform {
  margin-bottom: 24px;
}

.editor-entry-transform-slider-textboxes {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
}

.editor-entry-transform-slider-textboxes-input {
  width: 128px;
}

.editor-entry-transform-slider-textboxes-input:last-child
  .editor-entry-transform-slider-textboxes-input-inner {
  text-align: right;
}

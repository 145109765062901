.item-description {
    color: var(--theme-dark-2);
}

.search-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 64px;
}

.search-info-button button,
.search-info-button button:hover {
    background-color: white;
    padding: 0px;
}

.search-info-button button g {
    fill: var(--theme-light-1);
    transition: 0.2s;
}
.search-info-button button:hover g {
    fill: var(--theme-dark-2);
}
